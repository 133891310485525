<template>
  <div class="container" fluid="lg">
    <div class="row">
      <div class="col-12 text-center">
        <div class="p-5">
          <b-img :src="sypitarn_mascot" class="mx-auto" style="max-width: 200px" alt="Mascot"></b-img>
        </div>
        <h5>{{ $t('page_not_found') }}</h5>
        <router-link to="/">
          <b-button pill variant="primary px-4 my-4"> {{ $t('go_to_landing_page') }} </b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sypitarn_mascot: require('@/assets/images/sypitarn_mascot.png'),
    }
  },
}
</script>

<style></style>
